import React, { useState } from 'react';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  ErrorField,
  InputText,
  Textarea,
  StyledSelect,
  InputColor,
  TextEditor,
  Checkbox,
  AsyncStyledSelect,
} from '../../lib/HooksFormFields';
import ChildPagesField from '../ChildPagesField';
import ObjectArray from '../ObjectArray';
import InputDateTime from '../../lib/HooksFormFields/InputDateTime';
import InputMedia from '../../lib/HooksFormFields/InputMedia';
import { FileType } from '../../types/file';
import { IPage, PageField } from '../../types/page';

import styles from './Field.module.scss';
import FieldSchedule from '../FieldSchedule';
import FieldSlider from '../FieldSlider';
import ListTags from '../../lib/HooksFormFields/ListTags';
import CheckList from '../CheckList';
import { createLead } from '../../actions/pages';
// import Links from '../Links';
// import Checkbox from '../../lib/HooksFormFields/Checkbox';

const Field = ({
  field,
  defaultValue,
  control,
  errors,
  file = null,
  setValue,
  watch,
  pageId,
  pageForm,
  setIsSaved,
  submit,
}:{
  field: PageField,
  defaultValue?: any,
  pageForm: FieldValues,
  control: any,
  errors: any,
  pageId: string | null
  file?: FileType | null
  watch?: UseFormWatch<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  setIsSaved?: (bool: boolean) => void,
  submit: (data: any) => void,
}) => {
  const { error } = useSelector((store: any) => store).pagesReducer;
  const dispatch = useDispatch();
  const [leadError, setLeadError] = useState<string | null>();
  const [leadIsLoading, setLeadIsLoading] = useState(false);

  const rules = field.required ? { required: 'Ce champs est requis' } : {};

  function getError() {
    let message = errors && field.name && errors[field.name]?.message;
    const serverDuplicated = error === `La valeur du ou des champs suivant est déjà utilisé : ${field.name}.` && 'La valeur est déja utilisé dans une autre page';
    const keys = field?.name?.split('.');
    let obj = errors;
    keys.forEach((k) => {
      if (obj[k]) {
        obj = obj[k];
        if (obj.message) {
          message = obj.message;
        }
      }
    });
    if (!message && serverDuplicated) {
      message = serverDuplicated;
    }
    return message;
  }

  async function handleSubmit(obj: any) {
    return submit(obj);
  }

  async function handleSubmitLead() {
    setLeadError(null);
    const payload = {
      club: pageForm?.content?.leadKey,
      email: pageForm?.content?.prospectEmail || pageForm?.content?.email,
      lastname: pageForm?.content?.contact?.lastName,
      firstname: pageForm?.content?.contact?.firstName,
    };
    if (!payload.email) {
      setLeadError('l\'email est requis');
    }
    if (!payload.lastname) {
      setLeadError('le nom du contact est requis');
    }
    if (!payload.firstname) {
      setLeadError('le prénom du contact est requis');
    }
    setLeadIsLoading(true);
    await createLead(dispatch, pageForm._id, payload);
    setLeadIsLoading(false);
  }

  console.log(pageForm?.content?.isLead);

  return (
    <div className={`${styles.field}`}>
      {field.title && (
        <h2>{field.title}</h2>
      )}
      {field.type === 'text'
        && <>
          <InputText
            label={field.label}
            placeholder={field.placeholder || ''}
            name={field.name}
            control={control}
            rules={rules}
            heading={field.heading}
          />
        </>
      }
      {field.type === 'leadKey'
        && <>
          <InputText
            label={field.label}
            placeholder={field.placeholder || ''}
            name={field.name}
            control={control}
            rules={rules}
            heading={field.heading}
          />
          {pageForm?.content?.leadKey
          && <>
            <div className={styles.lead}>
              {!pageForm?.content?.isLead
                ? <>
              {leadIsLoading
                ? <span>Traitment en cours</span>
                : <button onClick={() => handleSubmitLead()}>
                Envoyer la demande de lead
              </button>
              }
              </>
                : <span>Le lead est actif</span>
              }
              {leadError && <ErrorField message={leadError} />}
            </div>
          </>
          }
        </>
      }
      {field.type === 'color' && (
        <InputColor
          label={field.label}
          name={field.name}
          control={control}
          rules={rules}
        />
      )}
      {field.type === 'checkbox' && (
        <Checkbox
          label={field.label}
          name={field.name}
          control={control}
        />
      )}
      {field.type === 'checkList' && watch && (
        <CheckList
          name={field.name}
          items={watch(field.name)}
          options={field.groupedOptions}
          handleChange={(array: string[]) => setValue(field.name, array)}
        />
      )}
      {field.type === 'richtext'
        && <>
          <TextEditor
            label={field.label}
            placeholder={field.placeholder || ''}
            name={field.name}
            control={control}
            rules={rules}
            maxlength={field.maxlength}
            defaultValue={defaultValue}
          />
        </>
      }
      {field.type === 'textarea' && (
        <Textarea
          label={field.label}
          placeholder={field.placeholder || ''}
          name={field.name}
          maxlength={field.maxlength}
          control={control}
          rules={rules}
        />
      )}
      {field.type === 'media' && pageId && (
        <InputMedia
          label={field.label}
          name={field.name}
          media={file}
          handleChange={(val) => setValue(field.name, val)}
          submit={(obj) => handleSubmit(obj)}
        />
      )}
      {field.type === 'date' && (
        <InputDateTime
          label={field.label}
          name={field.name}
          control={control}
          rules={rules}
        />
      )}
      {(field.type === 'select' && field?.options?.length > 0) && (
        <StyledSelect
          label={field.label}
          name={field.name}
          isMulti={field.isMulti}
          options={field.options}
          control={control}
          rules={rules}
        />
      )}
      {(field.type === 'selectAsync' && field.endpoint) && (
        <AsyncStyledSelect
          label={field.label}
          endpoint={field.endpoint}
          name={field.name}
          isMulti={field.isMulti}
          control={control}
          rules={rules}
        />
      )}
      {field.type === 'schedule' && (
        <FieldSchedule
          name={field.name}
          control={control}
          rules={rules}
        />
      )}
      {field.type === 'slider' && (
        <FieldSlider
          label={field.label}
          name={field.name}
          control={control}
          handleChange={(val) => {
            console.log(field.name, val);
            setValue(field.name, val);
          }}
          submit={(obj) => {
            handleSubmit(obj);
          }}
        />
      )}
      {field.type === 'tags' && (
        <ListTags
          name={field.name}
          tags={field.options}
          isMulti={field.isMulti}
          control={control}
          rules={rules}
        />
      )}
      {(field.type === 'pages' && pageId) && (
        <ChildPagesField
          label={field.label}
          type={field.page}
          create={field.create || 'Ajouter'}
          parent={pageId}
          pages={pageForm?.pages?.filter((p : IPage) => p.type === field.page) || []}
        />
      )}
      {field.type === 'array' && watch && setIsSaved
        && <>
          <ObjectArray
            label={field.label}
            create={field.create || 'Ajouter'}
            blocks={field.blocks}
            array={watch(field.name) || []}
            handleChangeArray={(val) => {
              setValue(field.name, [...val]);
              setIsSaved(false);
            }}
            name={field.name}
            item={field.item}
            className={field?.className}
            fields={field.fields}
            handleSubmit={(obj) => handleSubmit(obj)}
          />
        </>
      }
      {getError() && <ErrorField message={getError()}/>}
    </div>
  );
};

export default Field;
